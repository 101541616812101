var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "container px-8 mx-auto h-4/5" }, [
    _c(
      "h1",
      { staticClass: "my-6 text-2xl font-bold", attrs: { id: "table-title" } },
      [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")]
    ),
    _c(
      "div",
      { staticClass: "flex px-8 pt-4 border border-black bg-gray-50 " },
      [
        _c(
          "h2",
          { staticClass: "inline pb-2 font-bold border-b-4 border-green-500" },
          [_vm._v(" Packages ")]
        ),
        _c(
          "div",
          { staticClass: "flex flex-col items-end w-full" },
          [
            _c("router-link", { attrs: { to: { name: "package.create" } } }, [
              _c(
                "button",
                {
                  staticClass: "inline px-3 text-gray-500 pb-2text-lg",
                  attrs: { "data-cy": "create-package-button" }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: ["fa", "plus"] }
                  }),
                  _c("span", [_vm._v("Create new package")])
                ],
                1
              )
            ])
          ],
          1
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "overflow-y-scroll border-b border-l border-r border-black h-5/6"
      },
      [
        _c(
          "table",
          {
            staticClass: "w-full table-auto",
            attrs: { "aria-describedby": "table-title" }
          },
          [
            _c("thead", [
              _c("tr", { staticClass: "text-left" }, [
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByName()
                          }
                        }
                      },
                      [
                        _vm._v(" Package "),
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "name" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByVersion()
                          }
                        }
                      },
                      [
                        _vm._v(" Latest Version "),
                        _c("table-sort-arrow-component", {
                          attrs: {
                            sorting: _vm.sorting,
                            name: "latestVersion.version"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByUpdateDate()
                          }
                        }
                      },
                      [
                        _vm._v(" Updated "),
                        _c("table-sort-arrow-component", {
                          attrs: {
                            sorting: _vm.sorting,
                            name: "latestVersion.updatedDate"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c("th", {
                  staticClass: "sticky top-0 z-50 px-8 py-2 bg-gray-50",
                  attrs: { scope: "col" }
                })
              ])
            ]),
            _c(
              "tbody",
              _vm._l(this.packs, function(pack, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    staticClass: "skyhub-table-row",
                    on: {
                      click: function($event) {
                        return _vm.routeToPackageDetail(pack.id)
                      }
                    }
                  },
                  [
                    _c("td", { staticClass: "px-8 py-4" }, [
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("package-icon", {
                            staticClass: "w-12 h-12 py-4 mx-4 text-3xl",
                            attrs: {
                              backgroundColor: pack.iconColor,
                              icon: pack.icon,
                              packageFamily: pack.family
                            }
                          }),
                          _c("div", { attrs: { id: "package-name" } }, [
                            _c("p", { staticClass: "skyhub-text" }, [
                              _vm._v(" " + _vm._s(pack.name) + " ")
                            ]),
                            _c("p", { staticClass: "skyhub-subtext" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.shortenDescription(pack.description)
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ]),
                    _c("td", { staticClass: "px-8 py-4" }, [
                      pack.latestVersion != null
                        ? _c(
                            "div",
                            {
                              staticClass: "inline-block",
                              on: {
                                click: function($event) {
                                  if ($event.target !== $event.currentTarget) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(pack.latestVersion.version) + " "
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "px-8 py-4" }, [
                      pack.latestVersion != null
                        ? _c(
                            "div",
                            {
                              staticClass: "inline-block",
                              on: {
                                click: function($event) {
                                  if ($event.target !== $event.currentTarget) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              _c("time-component", {
                                attrs: { date: pack.latestVersion.updatedDate }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "td",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [
                        _c(
                          "drop-down",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "package.detail",
                                    params: { id: pack.id }
                                  }
                                }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "inline-flex items-center w-full px-4 py-2 text-sm leading-5 skyhub-table-row"
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "mr-2",
                                      attrs: { icon: ["fas", "list-ul"] }
                                    }),
                                    _vm._v(" View Details ")
                                  ],
                                  1
                                )
                              ]
                            ),
                            pack.latestVersion
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "inline-flex items-center w-full px-4 py-2 text-sm leading-5 skyhub-table-row",
                                    attrs: {
                                      href:
                                        _vm.apiBaseUrl +
                                        "/" +
                                        pack.latestVersion.packageLocation,
                                      download: ""
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "mr-2",
                                      attrs: { icon: ["fas", "download"] }
                                    }),
                                    _vm._v(" Download ")
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "package.detail.version.create",
                                    params: { id: pack.id }
                                  }
                                }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "inline-flex items-center w-full px-4 py-2 text-sm leading-5 skyhub-table-row"
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "mr-2",
                                      attrs: { icon: ["fas", "plus"] }
                                    }),
                                    _vm._v(" Publish New Version ")
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }